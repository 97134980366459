<template>
  <div>
    <div class="pb-4 pull-right">
      <b-button @click="createNote" class="btn btn-success" v-if="showButtons()">{{ $t("common.new") }}</b-button>
    </div>
    <table class="table table-bordered">
      <thead class="thead-light">
      <tr>
        <th class="text-left">
          Дата
        </th>       
        <th class="text-left">
          Предмет
        </th>
        <th class="text-left">
          Замечание и предложения
        </th>
        <th class="text-left">
          Отметка о выполнении
        </th>
        <th>          
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in notes" :key="item.id">
        <td>
          {{item.dateString}}
        </td>     
        <td>
          {{item.subjectName}}
        </td>
        <td>
          {{item.note}}
        </td>
        <td>
          {{item.result}}
        </td>
        <td>
          <a class="btn btn-icon btn-success" v-if="showButtons()"> 
            <i class="fa fa-pen" @click="editNote(item)"></i>
          </a>
          <a class="btn btn-icon btn-danger" v-if="showButtons()" style="margin-left: 5px"> 
            <i class="fa fa-trash" @click="deleteNote(item)"></i>
          </a> 
          <button class="btn btn-success" @click="resultNote(item)" style="margin-left: 5px"> 
            Отметить о выполнении
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <b-modal :ref="JOURNAL_NOTE_MODAL_NAME">
      <template v-slot:modal-title>
        {{ $t("common.new") }}
      </template>
      <b-form>
        <b-form-group>
          <template v-slot:label>
            Дата
          </template>
          <b-form-datepicker
              v-model="form.date"
              :state="formStates.date"
              :disabled="formResultMode"
              locale="ru"
          ></b-form-datepicker>
          <div class="invalid-feedback">{{ formSaveError.date }}</div>
        </b-form-group>
        <b-form-group v-if="mode === VIEW_MODE_GROUP">
          <template v-slot:label>
            Предмет
          </template>
          <b-form-select
              v-model="form.groupSubjectId"
              :options="groupSubjects"
              :state="formStates.groupSubjectId"
              :disabled="formResultMode"
          ></b-form-select>
          <div class="invalid-feedback">{{ formSaveError.groupSubjectId }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Замечания или предложения
          </template>
          <b-form-input
              v-model="form.note"
              :state="formStates.note"
              :disabled="formResultMode"
          ></b-form-input>
          <div class="invalid-feedback">{{ formSaveError.note }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Отметка о выполнении
          </template>
          <b-form-input
              v-model="form.result"
              :state="formStates.result"
          ></b-form-input>
          <div class="invalid-feedback">{{ formSaveError.result }}</div>
        </b-form-group>
      </b-form>

      <template v-slot:modal-footer>
        <b-button type="submit" @click="saveNote" variant="primary">{{
            $t("common.save")
          }}
        </b-button>
        <b-button type="reset" @click="closeNoteForm" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>
    
  </div>
</template>
<style>
</style>
<script>

import ApiService from "@/common/api.service";
import {mapGetters} from "vuex";

const VIEW_MODE_GROUP = "group";
const JOURNAL_NOTE_MODAL_NAME = "journal-notes-modal";

export default {
  name: "JournalNotes",
  components: {},
  props: {
    groupId: String,
    groupSubjectId: String,
    mode: String,
  },
  data() {
    return {
      notes:[],
      form:{
        date: new Date(),
        note:'',
        result:'',
        groupSubjectId: ""
      },
      formStates:{},
      formSaveError:{},
      groupSubjects:[],
      groupSubjectIdPar: "",
      formResultMode: false
    };
  },
  setup() {
    return { VIEW_MODE_GROUP, JOURNAL_NOTE_MODAL_NAME }
  },
  mounted() {
    this.groupSubjectIdPar = this.groupSubjectId;
    
    if (this.mode === VIEW_MODE_GROUP) {
      let $this = this;
      ApiService.querySecured("dictionaries/getGroupSubjectsForGroupForFilter",
          {params: {groupId: this.groupId}})
          .then(({data}) => {
            data.dictionary.unshift({ value: "", text: '' });
            $this.groupSubjects = data.dictionary;
          }).catch(({response}) => {
        console.log(response);
      });
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ])
  },
  methods: {
    reloadData(groupSubjectId1){
      if(typeof groupSubjectId1 != 'undefined'){
        this.groupSubjectIdPar = groupSubjectId1;
      }

      if (this.mode === VIEW_MODE_GROUP) {
        ApiService.querySecured("journalNote/list", {
          params: {groupId: this.groupId}
        }).then(({data}) => {
          this.notes = data;
        }).catch(({response}) => {
          console.log(response);
        });
      } else {
        ApiService.querySecured("journalNote/listForSubject", {
          params: {subjectId: this.groupSubjectIdPar}
        }).then(({data}) => {
          this.notes = data;
        }).catch(({response}) => {
          console.log(response);
        });
      }
    },
    createNote(){
      this.formResultMode = false;
      this.formStates = {};
      this.formSaveError = {};

      if (this.mode === VIEW_MODE_GROUP) {
        this.form = {
          groupId: this.groupId
        };
      }else{
        this.form = {
          groupSubjectId: this.groupSubjectIdPar
        };
      }

      this.$refs[JOURNAL_NOTE_MODAL_NAME].show();
    },

    saveNote(evt) {
      evt.preventDefault();
      let $this = this;

      this.formStates = {};
      this.formSaveError = {};

      ApiService.postSecured("journalNote/save", this.form)
          .then(function () {
            $this.$refs[JOURNAL_NOTE_MODAL_NAME].hide();
          
            $this.form = {};
            $this.reloadData();
          })
          .catch(({response}) => {
            
            if (response.data != null && response.data.errors != null) {
              $this.formSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.formStates[i] = false;
              }
            }
          });
    },
    editNote(subject) {
      this.formResultMode = false;
      this.openEditForm(subject);
    },
    resultNote(subject) {
      this.formResultMode = true;
      this.openEditForm(subject);
    },
    openEditForm(subject){
      this.formStates = {};
      this.formSaveError = {};

      this.form = subject;


      if (this.mode === VIEW_MODE_GROUP) {
        this.form['groupId'] = this.groupId;
      }else{
        this.form['groupSubjectId'] = this.groupSubjectIdPar;
      }

      this.$refs[JOURNAL_NOTE_MODAL_NAME].show();
    },
    deleteNote(subject) {
      let $this = this;
      if(confirm("Удалить замечение?")){
        ApiService.postSecured("journalNote/delete", {id: subject.id})
            .then(function () {
              $this.reloadData();
            })
            .catch(({response}) => {
              console.log(response)
            });
      }
    },
    closeNoteForm(evt) {
      evt.preventDefault();
      this.form = {};
      
      this.$refs[JOURNAL_NOTE_MODAL_NAME].hide();
    },
    
    showButtons(){
      return this.currentUser.role == 'ROLE_SCHOOL_ADMIN';
    }
  }
};
</script>
